import React, { useState } from "react";
import YouTube from "react-youtube";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import { getYouTubeId } from "../../helpers/getYouTubeId";
import {
  NodeInterface,
  YouTubeEmbedInterface
} from "../../interfaces/blogPost";
import { EmotionStyles } from "../../interfaces/emotion";
import { handlePlay, handleReady } from "./helpers";

export type YouTubeEmbedProps = NodeInterface<YouTubeEmbedInterface>;

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ node }) => {
  const {
    state: { isPlaying },
    dispatch
  } = usePlayer();
  const [error, setError] = useState(false);

  const { url, height = 390, width = 640 } = node;

  const styles: EmotionStyles = {
    container: {},
    videoOuter: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      width: "100%",
      height: 0,
      paddingBottom: "56.25%",
      "& .wmdj-youtube-embed": {
        width: "100%",
        height: "100%"
      },
      "& .wmdj-video-inner": {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
  };

  if (error) return null;

  return (
    <div css={styles.container}>
      <div css={styles.videoOuter}>
        <div className="wmdj-video-inner">
          <YouTube
            videoId={getYouTubeId(url)}
            opts={{
              height: String(height),
              width: String(width),
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
                modestbranding: 1,
                rel: 0
              }
            }}
            className="wmdj-youtube-embed"
            onReady={(e) => handleReady(e)}
            onPlay={(e) => handlePlay(e, isPlaying, dispatch)}
            onError={(e) => setError(true)}
            containerClassName="wmdj-video-inner"
          />
        </div>
      </div>
    </div>
  );
};

export default YouTubeEmbed;
