import { AuthorReference } from "./AuthorCard/AuthorReference";
import Figure from "./Figure";
import InstagramPost from "./InstagramPost";
import YouTubeEmbed from "./YouTubeEmbed";

const serializers = {
  types: {
    authorReference: AuthorReference,
    mainImage: Figure,
    instagramPost: InstagramPost,
    youTubeEmbed: YouTubeEmbed
  }
};

export default serializers;
