function getYouTubeId(url: string) {
  let urlSpec = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (typeof urlSpec[2] !== "undefined") {
    const ID = urlSpec[2].split(/[^0-9a-z_-]/i);
    return ID[0];
  } 
  return url;
}

export { getYouTubeId };
