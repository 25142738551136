import React from "react";
import {
  InstagramPostInterface,
  NodeInterface
} from "../../interfaces/blogPost";
import InstagramEmbed from "react-instagram-embed";
import { EmotionStyles } from "../../interfaces/emotion";

export type InstagramEmbedProps = NodeInterface<InstagramPostInterface>;
const instaClassName = "wmdj-insta-embed";
const styles: EmotionStyles = {
  container: {
    display: "flex",
    justifyContent: "center",

    [`& .${instaClassName}`]: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    }
  }
};

const InstagramPost: React.FC<InstagramEmbedProps> = ({ node }) => {
  const { url, showCaption } = node;

  return (
    <div css={styles.container}>
      <InstagramEmbed
        url={url}
        clientAccessToken={process.env.GATSBY_FB_ACCESS_TOKEN!}
        maxWidth={500}
        injectScript
        hideCaption={!showCaption}
        className={instaClassName}
      />
    </div>
  );
};

export default InstagramPost;
