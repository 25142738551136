import { Dispatch } from "react";
import { YouTubePlayer } from "youtube-player/dist/types";
import { PlayerStoreAction } from "../../../contexts/PlayerContextV2/interfaces/actions";
import { handleSetPlaying } from "../../Player/components/helpers";

export const handleReady = (e: { target: YouTubePlayer }) => {
  e.target.pauseVideo();
};

export const handlePlay = (
  e: { target: YouTubePlayer },
  isPlaying: boolean,
  dispatch: Dispatch<PlayerStoreAction>
) => {
  if (isPlaying) {
    handleSetPlaying(dispatch, false);
  }
};
